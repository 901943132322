<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">
        <div class="preview-title">
            <h1>{{ survey.title }}</h1>
        </div>
            <div v-if="survey" class="preview">
                <p>Please help us understand the issue with this survey.</p>

                <Alerts :_success="success" :_error="error"></Alerts>

                <div v-if="flag.id" class="border border-danger p-2 rounded mb-3">
                    <b>You flagged this survey</b> <br>
                    Reason: <i class="text-muted">{{ flag.reason }}</i> ~
                    {{ moment(flag.created_at).format("MMM D, YYYY HH:mm") }}
                </div>

                <form @submit.prevent="flagSurvey()">
                    <div class="mb-3">
                        <div class="form-check" v-for="(f, index) in flags" :key="index">
                            <input class="form-check-input" type="radio" :value="f" v-model="flag.reason" name="flag" required> 
                            <label class="form-check-label" for="">{{ f }}</label>
                        </div>                    
                    </div>
                    <div class="alert alert-warning">
                        You will no longer see this survey on your explore page.
                    </div>
                    <div>
                        <button type="submit" class="custom-btn custom-btn-danger">
                            <template v-if="flag.id">Save Changes</template>
                            <template v-else>Report</template>
                        </button>
                    </div>
                </form>
            </div>
    </div>
</template>

<script>
import Alerts from "../Helpers/Alerts";

export default {
    name: "Flag",
    components: {Alerts},
    props: ['code'],
    data() {
        return {
            survey: null,
            flag: {
                user_id: this.auth().id,
                reason: ''
            },
            flags: [
                'It is spam', 
                'It displays/shows sensitive information', 
                'It is rude or offensive', 
                'Actively promotes hate speech',
                'Intellectual property violation',
                'It contains inappropriate content',
                'Other...'
            ]
        }
    },
    mounted() {
        this.$http.get("/api/surveys/view/" + this.code).then(response => {
            this.survey = response.data.data
        });

        this.checkIfFlagged()
    },
    methods: {
        auth() {
            return JSON.parse(localStorage.getItem(process.env.VUE_APP_NAME + '_user'))
        },
        checkIfFlagged() {
            this.$http.get("/api/survey/flag/" + this.code + "?user_id=" + this.auth().id).then(response => {
                let flag = response.data.data
                if (flag.id) {
                    this.flag = flag
                }
            })
        },
        flagSurvey() {
            this.clear()
            this.success = 'Please wait...'
            this.$http.post("/api/survey/flag/" + this.code, this.flag).then(() => {
                this.success = 'Survey was flagged successfully'
                setTimeout(() => this.$router.push('/surveys'), 1000)

            }).catch(error => {
                this.showError(error)
            });
        }
    }
}
</script>

<style scoped>

</style>